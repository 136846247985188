import React from "react";

Reports.propTypes = {};

function Reports(props) {
  return (
    <div align="center">
      <p> Reports coming soon... </p>
    </div>
  );
}

export default Reports;
