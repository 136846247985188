import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import Home from "../pages/Home";
import DeskReservation from "../pages/DeskReservation";
import CancelReservation from "../pages/CancelReservation";
import BookMyDesk from "../pages/BookMyDesk";
import BlockDesk from "../pages/BlockDesk";
import Reports from "../pages/Reports";
import Admin from "../pages/Admin";
import { Auth } from "aws-amplify";

export class ReservationApp extends Component {
  constructor(props) {
    super(props);
    this._validAuthStates = ["signedIn", "signedOut"];
    this.state = {
      error: "",
    };
  }

  render() {
    if (
      this.props.authState === "signedIn" ||
      this.props.authState === "verifyContact"
    ) {
      return (
        <Router>
          <div>
            <Navbar
              userInfo={this.props.userInfo}
              authState={this.props.authState}
              updateUsername={this.props.updateUsername}
              onStateChange={this.props.onStateChange}
            />
            <Switch>
              <Route path="/" exact component={Home} />
              <Route path="/deskReservation" component={DeskReservation} />
              <Route path="/bookMyDesk" component={BookMyDesk} />
              <Route path="/blockDesk" component={BlockDesk} />
              <Route path="/manageReservation" component={CancelReservation} />
              <Route path="/reports" component={Reports} />
              <Route path="/admin" component={Admin} />
            </Switch>
          </div>
        </Router>
      );
    } else {
      return null;
    }
  }
}
