import React, { Component } from "react";
import { Locations } from "../../data/Locations";
import { Buildings } from "../../data/Buildings";
import { Levels } from "../../data/Levels";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.min.css";
import FloorMap from "../LevelMaps/FloorMap";
import DefaultPage from "../LevelMaps/DefaultPage";

export default class BookMyDesk extends Component {
  constructor(props) {
    super(props);
    this.handleFromDateChange = this.handleFromDateChange.bind(this);
    this.handleToDateChange = this.handleToDateChange.bind(this);
  }

  state = {
    fromDate: new Date(),
    toDate: new Date(),
    selectedLevel: "",
    isOpen: false,
  };

  handleFromDateChange(e) {
    console.log(e);
    this.setState({ fromDate: e });
  }

  handleToDateChange(e) {
    console.log(e);
    this.setState({ toDate: e });
  }

  handleToggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  render() {
    return (
      <>
        <nav className="navbar">
          <form>
            <div className="nav-left">
              <ul
                className={
                  this.state.isOpen ? "nav-links show-nav" : "nav-links"
                }
              >
                <li>
                  <label>
                    {" "}
                    Locations:
                    <select>
                      {Locations.map((location, index) => {
                        return (
                          <option key={location.id} value={location.title}>
                            {location.title}
                          </option>
                        );
                      })}
                    </select>
                  </label>
                </li>
                <li>
                  <label>
                    {" "}
                    Buildings:
                    <select>
                      {Buildings.map((building, index) => {
                        return (
                          <option key={building.id} value={building.title}>
                            {building.title}
                          </option>
                        );
                      })}
                    </select>
                  </label>
                </li>
                <li>
                  <label>
                    {" "}
                    Levels:
                    <select
                      onChange={(e) => {
                        this.setState({ selectedLevel: e.target.value });
                      }}
                    >
                      {Levels.map((level, index) => {
                        return (
                          <option key={level.id} value={level.title}>
                            {level.title}
                          </option>
                        );
                      })}
                    </select>
                  </label>
                </li>
                <li>
                  <label> From Date:</label>
                  <DatePicker
                    selected={this.state.fromDate}
                    onChange={this.handleFromDateChange}
                    dateFormat="MMMM d, yyyy"
                    className="form-control"
                  />
                </li>
                <li>
                  <label> TO Date: </label>
                  <DatePicker
                    selected={this.state.toDate}
                    onChange={this.handleToDateChange}
                    dateFormat="MMMM d, yyyy"
                    className="form-control"
                  />
                </li>
              </ul>
            </div>
          </form>
        </nav>
        <div>{this.renderSelectedLevelMap(this.state.selectedLevel)}</div>
      </>
    );
  }

  renderSelectedLevelMap(selectedLevel) {
    if (selectedLevel === "Level4") return <FloorMap />;
    else return <DefaultPage />;
  }
}
