export const Buildings = [
  {
    id: 0,
    title: 'North Ryde',
    selected: false,
    key: 'building'
  },
  {
    id: 1,
    title: 'Rouse Hill',
    selected: false,
    key: 'building'
  },
  {
    id: 2,
    title: 'Mascot',
    selected: false,
    key: 'building'
  }
]