import React, { Component } from "react";
import fflogo from "../../images/FUJIFILM_Slogan.png";
import { FaAlignRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Auth } from "aws-amplify";

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      auNumber: "",
      emailId: "",
    };

    this.signOut = this.signOut.bind(this);
    this.getLoggedInUser = this.getLoggedInUser.bind(this);
  }

  // ====================================================
  // signOut() : used to sign out user
  // custom sign out function; has been bound in constructor(props) as well
  async signOut() {
    await Auth.signOut()
      .then(() => {
        this.props.onStateChange("signIn", {});
        this.props.updateUsername(null);
        //this.setState({ authState: "signIn" });
        //this.setState({ user: null });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleToggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  handleChange(e) {
    console.log(e);
    this.setState({ id: e.value, name: e.label });
    this.handleChange = this.handleChange.bind(this);
  }

  async getLoggedInUser() {
    const currentUserInfo = await Auth.currentUserInfo();
    const userEmailId = currentUserInfo.attributes.email;
    this.setState({ emailId: userEmailId });
    return currentUserInfo;
  }

  render() {
    let currentUserInfo = this.getLoggedInUser();

    return (
      <nav className="navbar">
        <div className="nav-header">
          <Link to="/">
            <img className="ff-logo" src={fflogo} alt="Desk Reservation" />
          </Link>
          <div className="userInfoBar">
            <span>
              <span>{this.state.auNumber}</span>
            </span>
            <span>
              <span>{this.state.emailId}</span>
            </span>
            <button
              className="button-style"
              type="button"
              onClick={this.signOut}
            >
              Sign Out
            </button>
          </div>
          <button type="button" className="nav-btn" onClick={this.handleToggle}>
            <FaAlignRight className="nav-icon" />
          </button>
        </div>

        <ui className={this.state.isOpen ? "nav-links show-nav" : "nav-links"}>
          <Link to="/deskReservation">
            <li>Desk Reservation</li>
          </Link>
          <Link to="/manageReservation">
            <li>Manage Reservation</li>
          </Link>
          <Link to="/admin">
            <li>Admin</li>
          </Link>
        </ui>
      </nav>
    );
  }
}
export default Navbar;
