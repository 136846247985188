import React, { Component } from "react";
import { API, Auth } from "aws-amplify";
import { Locations } from "../JsonObjects/Locations";

import "react-datepicker/dist/react-datepicker.css";
import "./DeskReservation.css";
import "bootstrap/dist/css/bootstrap.min.css";
import FloorMap from "../LevelMaps/FloorMap";
import DefaultPage from "../LevelMaps/DefaultPage";

import configData from "../../config.json";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

var DeskData = {};
var key = "areas";
var mapNameKey = "name";
var key2 = "searchData";
var floorMapKey = "floorMapPath";
var mapHeightKey = "mapHeight";
var mapWidthKey = "mapWidth";

DeskData[key] = [];
DeskData[mapNameKey] = "";
DeskData[key2] = [];

//Form Validation function
function validate(state, building, level) {
  const errors = [];
  if (!building) {
    errors.push("Please select the value for building.");
  }
  if (!level) {
    errors.push("Please select the value for level.");
  }
  return errors;
}

class BlockDesk extends Component {
  constructor() {
    super();
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      buildings: ["Select Location"],
      levels: ["Select Level"],
      site: [],
      location: "",
      selectedBuilding: "",
      selectedLevel: "",
      //Properties required to populate map
      DeskData,
      isDefaultMap: true,
      isLevelMapPopulated: null,
      searchData: {},
      //FormValidation error var
      errors: [],
      //Spinner prop
      showLoader: false,
      // to refresh the floor map
      reloadFlorMap: "",

      closeDatePicker: false,
    };
  }

  componentDidMount() {
    this.setState({
      buildings: ["Select Location First"],
      levels: [{ id: "l00", title: "Select Building First", selected: false }],
    });

    let stateArray = [];
    API.get("siteAPI", "/site/id")
      .then((siteResp) => {
        stateArray = siteResp.map((site) => {
          let obj = {};
          obj.id = site.id;
          obj.stateName = site.stateName;
          obj.buildingName = site.buildingName;
          obj.levelName = site.levelName;
          //Fields for Floor Map
          obj.floorMapPath = site.floorMapPath;
          obj.mapHeight = site.mapHeight;
          obj.mapWidth = site.mapWidth;
          obj.mapName = site.mapName;
          obj.mapAreaShape = site.mapAreaShape;
          return obj;
        });
        this.setState({ site: stateArray });
      })
      .catch((error) => {
        console.log("error" + error.response);
      });
  }

  handleLocationChange = (event) => {
    let loc = event.target.value;
    this.setState({
      location: event.target.value,
    });
    //populate buildings dropdown
    let buildingArray = ["Please Select"];
    this.state.site.map((site, index) => {
      let obj = {};
      if (site.stateName === loc) {
        obj = site.buildingName;
        buildingArray.push(obj);
      }
      return;
    });
    const uniqueBuildingNames = buildingArray.filter(function (elem, pos) {
      return buildingArray.indexOf(elem) === pos;
    });
    console.log(uniqueBuildingNames);
    this.setState({ buildings: uniqueBuildingNames });
  };

  handleBuildlingChange = (event) => {
    let blding = event.target.value;
    this.setState({
      selectedBuilding: event.target.value,
    });
    //populate level dropdown
    let levelArray = [{ id: "1", title: "Please Select", selected: false }];
    this.state.site.map((site, index) => {
      let obj = {};
      if (site.buildingName === blding) {
        obj.id = site.id;
        obj.title = site.levelName;
        obj.selected = false;
        levelArray.push(obj);
      }
      return;
    });
    this.setState({ levels: levelArray });
  };

  handleLevelChange = (event) => {
    this.setState({
      selectedLevel: event.target.value,
    });
  };

  updateFloorMap = (loadFloorMapFlag) => {
    this.setState({ reloadFlorMap: loadFloorMapFlag });
    if (this.state.reloadFlorMap === "YES") {
      this.getMapMasterData();
    }
  };

  async getMapMasterData() {
    this.setState({
      searchData: {
        blockFlag: "Y",
        selectedState: this.state.location,
        selectedBuilding: this.state.selectedBuilding,
        selectedLevel: this.state.selectedLevel,
      },
      isSubmitted: true,
    });

    this.setState({ isSubmitted: true });
    var floorMapPath;
    var mapHeight;
    var mapWidth;
    var mapName;
    var mapAreaShape;
    //Fetch site Map details from populated site array
    this.state.site.map((site, index) => {
      if (site.levelName === this.state.selectedLevel) {
        floorMapPath = site.floorMapPath;
        mapHeight = site.mapHeight;
        mapWidth = site.mapWidth;
        mapName = site.mapName;
        mapAreaShape = site.mapAreaShape;
      }
    });

    //populate image map for selected site
    //Get desk deatils for selected site
    let deskAPIParams = {
      queryStringParameters: {
        siteId: this.state.selectedLevel,
      },
    };
    let currentUser = Auth.user.attributes.email;
    API.get("deskAPI", "/desk", deskAPIParams)
      .then((deskResp) => {
        DeskData[key] = [];
        DeskData[mapNameKey] = "";
        DeskData[floorMapKey] = configData.DEFAULT_FLOOR_MAP_PATH;
        DeskData[mapWidthKey] = configData.DEFAULT_FLOOR_MAP_WIDTH;
        DeskData[mapHeightKey] = configData.DEFAULT_FLOOR_MAP_HEIGHT;
        deskResp.Items.map((desk) => {
          var status = desk.deskNumber;
          var fillColor = "#00b200";
          if (desk.isBlocked === "Y") {
            status = "Blocked";
            fillColor = "grey";
          }
          let coordsObject = [];
          coordsObject.push(desk.xCoord);
          coordsObject.push(desk.yCoord);
          coordsObject.push(desk.x1Coord);
          coordsObject.push(desk.y1Coord);
          var area1 = {
            shape: mapAreaShape,
            name: desk.deskNumber,
            alt: status,
            preFillColor: fillColor,
            coords: coordsObject,
            lineWidth: 3,
            href: "#",
            target: "_blank",
          };
          DeskData[key].push(area1);
          DeskData[mapNameKey] = mapName;
          DeskData[mapHeightKey] = mapHeight;
          DeskData[mapWidthKey] = mapWidth;
          DeskData[floorMapKey] = floorMapPath;
          return;
        });
        this.setState({ DeskData: DeskData });
      })
      .catch((error) => {
        console.log("error" + error);
      });
    this.setState({ isLevelMapPopulated: true, isDefaultMap: false });
  }

  //Populate Map data on submit
  handleSubmit = (event) => {
    event.preventDefault();
    //Form Validation changes
    const errors = validate(
      this.state.selectedState,
      this.state.selectedBuilding,
      this.state.selectedLevel
    );
    if (errors.length > 0) {
      this.setState({ errors });
      return;
    }
    this.setState({ errors });
    this.setState({
      showLoader: true,
    });
    this.getMapMasterData();
    this.closeLoader();
  };

  closeLoader = () => {
    setTimeout(() => {
      this.setState({
        showLoader: false,
      });
    }, 7000);
  };

  render() {
    const { errors } = this.state;

    return (
      <div className="reservationParent">
        <form>
          <div className="ReservationDiv">
            <label className="comp-style"> Location: </label>
            <select
              className="dropdown-style"
              value={this.state.location}
              onChange={this.handleLocationChange}
              onSelect={this.handleLocationChange}
            >
              {Locations.map((location) => {
                return (
                  <option key={location.id} value={location.title}>
                    {location.title}
                  </option>
                );
              })}
            </select>

            <label className="comp-style">Building:</label>
            <select
              className="dropdown-style"
              value={this.state.selectedBuilding}
              onChange={this.handleBuildlingChange}
              onSelect={this.handleBuildlingChange}
            >
              {this.state.buildings.map((building, index) => {
                return (
                  <option key={index} value={building}>
                    {building}
                  </option>
                );
              })}
            </select>

            <label className="comp-style">Level:</label>
            <select
              className="dropdown-style"
              value={this.state.selectedLevel}
              onChange={this.handleLevelChange}
            >
              {this.state.levels.map((level, index) => {
                return (
                  <option key={index} value={level.title}>
                    {level.title}
                  </option>
                );
              })}
            </select>

            <button
              className="button-style"
              type="button"
              onClick={this.handleSubmit}
            >
              Submit
            </button>
          </div>
          {errors.map((error) => (
            <strong>
              <p className="error" key={error}>
                Error: {error}
              </p>
            </strong>
          ))}
          <div className="loaderDiv">
            {this.state.showLoader && (
              <Loader type="Puff" color="#00BFFF" height={100} width={100} />
            )}
          </div>
          {this.state.errors.length < 1 &&
            this.state.isLevelMapPopulated &&
            this.state.showLoader === false && (
              <FloorMap
                MasterData={this.state.DeskData}
                SearchData={this.state.searchData}
                LoadFloorMap={this.updateFloorMap}
              />
            )}
          {this.state.isDefaultMap && <DefaultPage />}
        </form>
      </div>
    );
  }
}

export default BlockDesk;
