export const Levels = [
  {
    id: 0,
    title: 'Level3',
    selected: false,
    key: 'level3'
  },
  {
    id: 1,
    title: 'Level4',
    selected: false,
    key: 'level4'
  },
  {
    id: 2,
    title: 'Level5',
    selected: false,
    key: 'level5'
  }
]