import React, { Component } from "react";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import { API, Auth } from "aws-amplify";
import "./CancelReservation.css";
import "./ReservationModal.css";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

export class ReserveDeskModal extends Component {
  constructor(props) {
    super(props);
    console.log(props.selecteddesk);
    this.state = {
      message: "",
      reservationResult: false,
      //Spinner prop
      showLoader: false,
      disabled: true,
    };
  }

  handleSubmit = (event) => {
    this.setState({
      showLoader: true,
    });
    event.preventDefault();
    var tempDate = new Date().getTime();
    let currentUser = Auth.user.attributes.email;

    let result = true;
    let queryParam = {
      queryStringParameters: {
        //siteId: event.target.siteName.value,
        siteId: event.target.siteLevel.value,
        deskId: event.target.deskNumber.value,
        reservedFromDate: event.target.startDate.value,
        reservedToDate: event.target.endDate.value,
      },
    };

    API.get("validationAPI", "/validateReservation", queryParam)
      .then((resp) => {
        result = false;
        console.log(result);
      })
      .catch((error) => {
        console.log("error" + error);
      });

    if (result) {
      let reservationRecord = {
        id: tempDate,
        reservedFromDate: event.target.startDate.value,
        reservedToDate: event.target.endDate.value,
        deskId: event.target.deskNumber.value,
        // siteId: event.target.siteName.value,
        siteId: event.target.siteLevel.value,
        userName: currentUser,
      };
      this.saveReservation(reservationRecord);
    }
    this.closeLoader();
  };

  closeLoader = () => {
    setTimeout(() => {
      this.setState({
        showLoader: false,
      });
    }, 3000);
  };

  async saveReservation(reservationRecord) {
    API.post("reservationAPI", "/reservation", {
      body: reservationRecord,
    })
      .then((result) => {
        console.log("reservation saved  ...");
        this.setState({ reservationResult: true });
        this.setState({ message: result.success });
        this.props.refreshfloormap("success");
        console.log("Inside Email block  ...");
        let sendEmailApiParams = {
          queryStringParameters: {
            userEmailId: reservationRecord.userName,
            deskId: reservationRecord.deskId,
            siteId: reservationRecord.siteId,
            reservedFromDate: reservationRecord.reservedFromDate,
            reservedToDate: reservationRecord.reservedToDate,
          },
        };
        API.get("emailNotificationAPI", "/sendEmail", sendEmailApiParams)
          .then((resp) => {
            console.log(resp);
          })
          .catch((error) => {
            console.log("error" + error);
          });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ message: "Reservation Failed!" });
      });
    await this.timeout(2000);
  }

  timeout(delay) {
    return new Promise((res) => setTimeout(res, delay));
  }

  render() {
    return (
      <Modal
        {...this.props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Reserve My Desk
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="loaderDiv">
            {this.state.showLoader && (
              <Loader type="Puff" color="#01916D" height={100} width={100} />
            )}
          </div>
          <div className="Message">
            <p>{this.state.message}</p>
          </div>
          <div className="container">
            {!this.state.showLoader && this.state.message === "" && (
              <Row>
                <Col sm={6}>
                  <Form onSubmit={this.handleSubmit}>
                    <Form.Group>
                      <Form.Label>
                        <b>Start Date </b>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="startDate"
                        defaultValue={
                          this.props.selectedreservation.reservStartDate
                        }
                        disabled={this.state.disabled}
                      />
                      <Form.Label>
                        <b>End Date</b>{" "}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="endDate"
                        defaultValue={
                          this.props.selectedreservation.reservEndDate
                        }
                        disabled={this.state.disabled}
                      />
                      <Form.Label>
                        <b>Site State </b>
                      </Form.Label>
                      <Form.Control
                        disabled
                        type="text"
                        name="siteName"
                        defaultValue={
                          this.props.selectedreservation.selectedState
                        }
                        disabled={this.state.disabled}
                      />
                      <Form.Label>
                        <b>Site Level </b>
                      </Form.Label>
                      <Form.Control
                        disabled
                        type="text"
                        name="siteLevel"
                        defaultValue={
                          this.props.selectedreservation.selectedLevel
                        }
                      />
                      <Form.Label>
                        <b>Desk Name </b>
                      </Form.Label>
                      <Form.Control
                        disabled
                        type="text"
                        name="deskNumber"
                        defaultValue={this.props.selecteddesk}
                        disabled={this.state.disabled}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Button type="submit">Reserve</Button>
                    </Form.Group>
                  </Form>
                </Col>
              </Row>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    );
  }
}
