import React, { Component } from "react";
import "./CancelReservation.css";
import CSVReader from "react-csv-reader";
import { API } from "aws-amplify";

class Admin extends Component {
  handleFileUpload = (data, fileInfo) => {
    console.dir(data, fileInfo);
    var i;
    var cnt = 0;
    for (i = 0; i < data.length; i++) {
      var j;
      var deskDeatils = data[i];
      var id;
      var x;
      var y;
      var x1;
      var y1;
      cnt++;
      var tempDate = new Date().getTime() + cnt;
      console.log(tempDate);
      for (j = 0; j < deskDeatils.length; j++) {
        var id = tempDate;
        var x = parseInt(deskDeatils[3], 10);
        var y = parseInt(deskDeatils[4], 10);
        var x1 = parseInt(deskDeatils[5], 10);
        var y1 = parseInt(deskDeatils[6], 10);
      }
      let deskRecord = {
        id: id,
        deskNumber: deskDeatils[0],
        isBlocked: deskDeatils[1],
        siteId: deskDeatils[2],
        xCoord: x,
        yCoord: y,
        x1Coord: x1,
        y1Coord: y1,
      };
      API.post("deskAPI", "/desk", {
        body: deskRecord,
      })
        .then((result) => {
          console.log(result);
          this.setState({ message: result.success });
        })
        .catch((error) => {
          console.log(error);
          this.setState({ message: "Desk details Insert Failed!" });
        });
    }
  };

  render() {
    return (
      <>
        <div>
          <br></br>
          <br></br>
        </div>
        <div align="center">
          <p>
            <b>Please upload CSV file of floor map image coordinates::</b>
          </p>
          <CSVReader onFileLoaded={this.handleFileUpload} />
        </div>
      </>
    );
  }
}
export default Admin;
