export const Locations = [
    {
      id: 0,
      title: 'NSW',
      selected: false,
      key: 'location'
    },
    {
      id: 1,
      title: 'VIC',
      selected: false,
      key: 'location'
    },
    {
      id: 2,
      title: 'QLD',
      selected: false,
      key: 'location'
    },
    {
      id: 3,
      title: 'WA',
      selected: false,
      key: 'location'  
    },
    {
      id: 4,
      title: 'SA',
      selected: false,
      key: 'location'  
    },
    {
      id: 5,
      title: 'NT',
      selected: false,
      key: 'location'  
    }
  ]